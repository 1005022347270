import './App.css';
import Centerpiece from './components/Centerpiece.tsx';
import Navbar from './components/Navbar.tsx';
import Separator from './components/Separator.tsx';

function App() {
  
  return (
    <div className="App">
      <Navbar/>
      {/* <Separator/> */}
      <Centerpiece/>
    </div>
  );
}

export default App;
