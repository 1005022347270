// src/components/Centerpiece.js
import React from "react";
import "./Centerpiece.css";

const Centerpiece = () => {
  return (
    <div className="centerpiece">
      <h1>KUMAR ASHISH</h1>
      <p>I'm a Backend Engineer</p>
    </div>
  );
};

export default Centerpiece;
