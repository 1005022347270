// src/components/Navbar.js
import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.setAttribute(
      "data-theme",
      darkMode ? "light" : "dark"
    );
  };
  return (
    <nav className="navbar">
      <div className="navbar-logo">KRASH.SH</div>
      <ul className="navbar-links">
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/17NBlFbDTuGmKpBPpMH7XLOdvv6MZwXiT/view?usp=sharing"
          >
            Resume
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.linkedin.com/in/krashcan/">
            LinkedIn
          </a>
        </li>
        <li>
          <a target="_blank" href="https://x.com/krash_can">
            Contact
          </a>
        </li>
        <li></li>
      </ul>
      <div className="navbar-template">
        <button className="toggle-button" onClick={toggleDarkMode}>
          {darkMode ? "Light Mode" : "Dark Mode"}
        </button>
      </div>
      {/* <hr /> */}
    </nav>
  );
};

export default Navbar;
